<template>
  <div>
    <div>
      <headerTop></headerTop>
    </div>
    <div>
      <headerMin :searchState="searchState"></headerMin>
    </div>
    <div class="upimg_content wrap">
      <!-- 索引 -->
      <div class="indexes wrap"><span @click="$router.push('/')">首页</span> > 药师审方</div>
      <div class="up_tips">注意：拍照上传处方单请保持光线充足、字体清晰以提高处方单审核通过的几率</div>
      <div class="box">
        <img v-show="upimgs !='' " :src="upimgs" class="upShow" alt />
        <div class="upset" v-show="upimgs == ''">
          <el-upload
            class="upas"
            :action="imageUrl"
            list-type="picture-card"
            :on-success="UserImgSuccess"
            :before-upload="beforeAvatarUpload"
            :on-remove="UserImgtRemove"
          >
            <img class="updata" src="@/assets/image/Uploadprescription_Sendpictures@2x.png" alt />
          </el-upload>
        </div>
      </div>
      <div class="up_bottom">
        <p class="p1" @click="UserImgtRemove">取消图片</p>
        <span></span>
        <p class="p2" @click="submitImg">确认提交</p>
      </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
import publicBottom from "@/components/public/public_bottom.vue";

import { uploadInquiryImg } from "@/request/index"; // 上传问诊图片
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  props: [],
  data() {
    return {
      searchState: 0,
      search: "",
      upimgs: ""
    };
  },
  created() {
    console.log(this.imageUrl)
    let datas = localStorage.getItem("lzyyToken");
    this.uid = this.$route.query.uid;  // 药师uid

    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    GoDoctorDetails() {
      this.$router.push({ path: "/doctor/doctorDetails" });
    },
    GoAllGoods() {
      this.$router.replace({ path: "/allGoods" });
    },
    //  个人 头像
    UserImgSuccess(res) {
      console.log(res)
      this.upimgs = res.data.file.url;
    },
    // 个人头像 删除
    UserImgtRemove() {
      this.upimgs = "";
    },
    // 上传问诊图片
    submitImg() {
      uploadInquiryImg({
        token: this.$token,
        pic: this.upimgs,
        yaoshi_uid: this.uid
      }).then(res => {
        if(res.code == 1) {
          this.$message({
            type: "success",
            message: "上传成功！"
          })
          this.$router.push("/shenfang/index")
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
@import "./shenfang";
</style>